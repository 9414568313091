import { type IDirectoryUserLocation } from "src/apis/companies";
import { InterestsValues, WorkStylesValues } from "../constants";

export type UserType =
  | "guest"
  | "individualGuest"
  | "host"
  | "admin"
  | "companyUser"
  | "companyAdmin"
  | "receptionist";

export type PostLoginRouteConfig = Partial<Record<UserType, string>> &
  Record<"fallback", string>;

// TODO: redirect receptionist to /company/visits
export const defaultPostLoginRouteConfig: PostLoginRouteConfig = {
  fallback: "/me/welcome",
  host: "/host",
  companyAdmin: "/company/dashboard",
};

export interface IUserCompanyProperties {
  department?: string;
  location?: IDirectoryUserLocation;
  areValuesSetByDirectory?: boolean;
}

export interface User {
  _id: string;
  type: UserType;
  spaces: string[];
  preferredLocations?: string[];
  interests: InterestsValues[];
  workstyles: WorkStylesValues[];
  objectives: string[];
  email: string;
  phone: string;
  signUpStage: number;
  firstName: string;
  lastName: string;
  chosenProfilePic?: string;
  facebookProfilePic?: string;
  uploadedProfilePic?: string;
  linkedinProfilePic?: string;
  stripeChargesEnabled?: boolean;
  stripeCustomerId?: string;
  stripeAccountId?: string;
  settleBookingsManually?: boolean;
  company?: string;
  department?: string;
  createdAt: string;
  restoredAt?: string;
  emailVerified?: boolean;
  companyProperties?: IUserCompanyProperties;
  policyAgreed?: boolean;
  deletedAt?: Date;
  slackInfo?: {
    connected?: boolean;
    isEnterpriseInstall?: boolean;
    enterpriseId?: string;
    teamId?: string;
    userId?: string;
  };
  hasPassword?: boolean;
  isIncognito?: boolean;
  isIncognitoReplacement?: boolean;
  companyName?: string;
  jobTitle?: string;
  isEventOrganizer?: boolean;
}

export type SlimPublicUser = Pick<
  User,
  | "_id"
  | "firstName"
  | "chosenProfilePic"
  | "facebookProfilePic"
  | "uploadedProfilePic"
  | "linkedinProfilePic"
>;
